.header {
  padding-left: 0;
  background-image: url(./pexels-pixabay-66869.jpg);
  background-size: cover;
  background-color: #56951c;
  color: white;
  min-height: 10em;
}

.navLink {
  margin-left: 1em;
  margin-right: 1em;
  padding: 0.4em 1em 0.5em 1em;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.6em;
}

.navLink.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.navLink a {
  color: white;
  font-family: Cabin, sans-serif;
  text-shadow: 0px 0px 5px rgba(0,0,0,0.3);
}
