.footer {
  background-color: #eee;
  color: #999;
}

.footer a {
  color: #999;
}

.payment-method-btn {
  border: 0;
  background-color: transparent;
}
