.pageContact {
  color: #677788;
}

.cardContact {
  border-width: 0;
  box-shadow: 0 12px 15px rgba(140,152,164,.1);
  border-radius: .75rem;
}

.cardContact .card-body .card-title {
  color: var(--primary);
}
