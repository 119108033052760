.headerRow {
  padding-top: 3em;
  padding-bottom: 2em;
}

.frontCard {
  padding: 1.5rem;
  min-height: 30rem;
  color: white;
  background: #1584c0;
}

.pros {
  background: linear-gradient(320deg, #a3637a 0%, #1a82be 100%);
}

.cons {
  background: linear-gradient(320deg, #f35152 0%, #5075a3 100%);
}

.fa-ul > li {
  margin-bottom: 0.5em;
}

.prosList .fa-li {
  color: #89cc30;
}

.consList .fa-li {
  color: var(--primary);
}

.card h4 {
  font-family: "Segoe UI";
  font-size: 1.3em;
  font-weight: 700;
}

.personRow {
  padding-top: 3em;
}

.personPic {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.quoteIcon {
  font-size: 2em;
  z-index: 0;
  color: var(--primary);
}

.quote {
  font-size: 2em;
}

.footnote {
  color: #888;
}
