// https://create-react-app.dev/docs/adding-bootstrap/#using-a-custom-theme

// Override default variables before the import
// $body-bg: #000;

$theme-colors: (
  'primary': #f25757
);

// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Cabin&family=Julius+Sans+One&display=swap');

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

// Overrides

html, body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

h1,h2,h3,h4 {
  font-family: 'Julius Sans One';
}

.content {
  // with this we get sticky footer
  // https://css-tricks.com/couple-takes-sticky-footer/
  flex: 1 0 auto;
}

.greyContainer {
  background: #f6f7f8;
}

.header, .footer {
  // do not shrink these with sticky footer
  flex-shrink: 0;
}

@include media-breakpoint-up(xs) {
  .logoContainer {
    padding-top: 3em;
    text-align: center;
  }

  #logo {
    width: 8em;
  }
}

@include media-breakpoint-up(sm) {
  #logo {
    left: 2em;
    width: 12em;
  }
}
